import React from "react"
import styled from "styled-components"
import { typeScale, perfectFourth, navy } from "../utils"
import { NarrowWrapper } from "./Layout"
import NewsletterForm from "./Forms/NewsletterForm"

const CallToAction = () => {
  return (
    <NarrowWrapper>
      <CTAWrapper>
        <div className="flow">
          <CTAText>
            Get notified when new content on CSS Academy is launched.
          </CTAText>
          <NewsletterForm button="Keep me updated" />
          <OptOut>Opt out at any time.</OptOut>
        </div>
      </CTAWrapper>
    </NarrowWrapper>
  )
}

export const CTAWrapper = styled.section`
  text-align: center;
  --flow-space: ${perfectFourth[400]};
`

export const CTAText = styled.h4`
  color: ${navy[500]};
  font-size: ${typeScale.header4};
  line-height: 1.2;
  padding-block-end: ${perfectFourth[200]};
`

export const OptOut = styled.p`
  font-size: ${typeScale.smallText};
  color: ${navy[300]};
  letter-spacing: 0.25px;
`

export default CallToAction
