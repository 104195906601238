import React from "react"
import { DotsFullWidth, InsideWrapper } from "../Layout"
import { HeaderTwo } from "../Headers"
import FormCourse from "./FormCourse"

const FeaturedCourse = () => {
  return (
    <DotsFullWidth>
      <InsideWrapper>
        <div className="flow">
          <HeaderTwo>
            Learn and master a topic at a time<span>.</span>
          </HeaderTwo>
          <FormCourse />
        </div>
      </InsideWrapper>
    </DotsFullWidth>
  )
}

export default FeaturedCourse
