import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import FeaturedCourse from "../components/Academy/FeaturedCourse"
import CallToAction from "../components/CallToAction"
import Posts from "../components/Posts"
import styled from "styled-components"
import { perfectFourth } from "../utils"
import Button from "../components/Buttons"
import { ContentWrapper } from "../components/Layout"
import SEO from "../components/seo"
import usePosts from "../hooks/usePosts"

const Home = () => {
  const { lastthree } = usePosts()

  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Hero />
        <FeaturedCourse />
        <ContentWrapper>
          <Posts posts={lastthree.posts} title="Learn bits at a time" />
          <CTA>
            <Button primary={false} link={"/blog/"} title={"View Blog"} />
          </CTA>
        </ContentWrapper>
        <CallToAction />
      </Layout>
    </>
  )
}

export const CTA = styled.div`
  padding-block-start: ${perfectFourth[400]};
`

export default Home
