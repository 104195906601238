import React from "react"
import styled from "styled-components"
import { perfectFourth, breakpoint, typeScale } from "../../utils"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { HeaderThree } from "../Headers"
import Button from "../Buttons"

const FormCourse = () => {
  const data = useStaticQuery(courseImage)
  return (
    <FormCourseWrapper>
      <TextWrapper>
        <div className="flow">
          <HeaderThree>Create Beautiful Forms Course</HeaderThree>
          <p>
            Learn how to create and layout beautiful and useable forms, as well
            as all the mini interactions that will make your forms feel polished
            and a pleasure to complete.
          </p>
          <Button
            primary={false}
            link={"/academy/beautiful-forms"}
            title={"Learn more"}
          />
        </div>
      </TextWrapper>
      <ImageWrapper>
        <Image
          fixed={data.file.childImageSharp.fixed}
          alt="Beautiful and Accessible Forms on CSS Academy: A visual idea of some of the forms you will learn to create with CSS."
        />
      </ImageWrapper>
    </FormCourseWrapper>
  )
}

export const FormCourseWrapper = styled.section`
  display: grid;
  grid-gap: ${perfectFourth[200]};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
  }
`
export const TextWrapper = styled.div`
  padding-block-start: ${perfectFourth[500]};
  padding-inline-end: ${perfectFourth[600]};
  @media ${breakpoint.md} {
    padding-inline-end: ${perfectFourth[300]};
    text-align: center;
  }
  p {
    font-size: ${typeScale.paragraph};
    line-height: 1.6;
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  @media ${breakpoint.md} {
    order: -1;
    block-size: 250px;
  }
  img {
    @media ${breakpoint.md} {
      margin-inline-start: auto;
      margin-inline-end: auto;
      margin-block-start: ${perfectFourth[600]};
    }
  }
`

const courseImage = graphql`
  {
    file(relativePath: { eq: "form-course-examples.png" }) {
      childImageSharp {
        fixed(width: 690, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default FormCourse
