import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "./Layout"
import Button from "./Buttons"
import { HeaderOne } from "./Headers"
import { typeScale, breakpoint, perfectFourth } from "../utils"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const Hero = () => {
  const data = useStaticQuery(heroImage)
  return (
    <ContentWrapper>
      <HeroWrapper>
        <TextWrapper>
          <p>CSS Academy</p>
          <div className="flow">
            <HeaderOne>
              Level up your CSS<span>.</span>
            </HeaderOne>
            <Subtitle>
              Learn to create any design to look exactly how you want,
              effortlessly. Learn something new regardless of whether you’re
              just starting out or have been writing CSS for years.
            </Subtitle>
            <div className="button-wrapper">
              <Button
                primary={true}
                link={"/academy/"}
                title={"Check out some courses"}
                color={"yellow"}
                arrow={"true"}
              />
            </div>
          </div>
        </TextWrapper>
        <ImageWrapper>
          <Image
            fixed={data.file.childImageSharp.fixed}
            alt="CSS Academy: A visual of all the things you can create when you know CSS well"
          />
        </ImageWrapper>
      </HeroWrapper>
    </ContentWrapper>
  )
}

export const HeroWrapper = styled.section`
  display: grid;
  grid-gap: ${perfectFourth[200]};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  @media ${breakpoint.md} {
    grid-template-columns: 1fr;
  }
`

export const TextWrapper = styled.div`
  padding-inline-start: ${perfectFourth[300]};
  padding-block-start: ${perfectFourth[500]};
  padding-inline-end: ${perfectFourth[600]};
  @media ${breakpoint.md} {
    padding-inline-end: ${perfectFourth[300]};
  }
  .button-wrapper {
    white-space: nowrap;
    @media ${breakpoint.md} {
      text-align: center;
    }
  }
`
export const Subtitle = styled.p`
  font-size: ${typeScale.subtitle};
  @media ${breakpoint.md} {
    text-align: center;
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  @media ${breakpoint.md} {
    display: none;
  }
  img {
    @media ${breakpoint.md} {
      margin-inline-start: auto;
      margin-inline-end: auto;
      margin-block-start: ${perfectFourth[600]};
    }
  }
`

const heroImage = graphql`
  {
    file(relativePath: { eq: "home-hero-visual.png" }) {
      childImageSharp {
        fixed(width: 690, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Hero
